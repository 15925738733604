import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>IRONMAN 70.3 Chattanooga Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="IRONMAN 70.3 Chattanooga Race Report" />
        <meta
          property="og:title"
          content="IRONMAN 70.3 Chattanooga Race Report"
        />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/chatt703-race-report/meta.jpg"
        />
        <meta
          property="og:image"
          content="https://colinlord.com/images/chatt703-race-report/meta.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/chatt703-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">
            IRONMAN 70.3 Chattanooga Race Report
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">May 22, 2022</p>
            <p className="lead mb-0">Chattanooga, TN</p>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Background</h1>
          <p>
            As long as I am physically capable, I’d like to do IRONMAN 70.3
            Chattanooga every year. It’s only two hours away from Nashville. The
            course is split between Tennessee and Georgia, my two favorite
            states, and there’s a Taco Mac with all the beers only a couple of
            blocks away from the finish line.
          </p>

          <p>
            I’m admittedly biased, but Chattanooga is such a fantastic host
            city.
          </p>

          <p>
            One quirk of the Chattanooga race is that it’s actually 70.5 miles.
            We swim an extra 0.2 miles in the Tennessee River. So despite having
            two 70.3 races on the calendar in May, the distances ended up being
            70.5 and 69.1 after storms wiped out the swim in IRONMAN 70.3 Gulf
            Coast.
          </p>

          <p>Someday I’ll do the standard 70.3 distance again!</p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Pre-Race</h1>
          <p className="pb-4">
            One of the more stressful parts of these bigger IRONMAN races is
            trying to find parking, particularly on race morning. Since I had
            signed up for this race a year ago, I was able to snag a hotel room
            in the heart of downtown. When we checked in, the view from our room
            actually looked out onto the river and the race village!
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/hotel-view.jpg" alt="" />
            </figure>
          </div>
          <p>
            Everything went smoothly leading into the race. I checked in,
            dropped off my bike, and then got a chance to catch up with Brian.
            He’s the one who peer pressured me into a sprint triathlon a few
            years back. So cool that we’re now racing our second IRONMAN 70.3
            together! Races are always more fun when you have friends out on the
            course.
          </p>
          <p>
            The only hiccup was the threat of storms on race day. After storms
            damaged bikes in transition before IRONMAN 70.3 Gulf Coast, the
            threat of even a drop of rain or gust of wind made me nervous!
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/forecast.jpg" alt="" />
            </figure>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Morning</h1>
          <p>
            I woke up on race morning before my alarm, as always. I have never
            actually slept to the time I set on my watch. Those pre-race jitters
            and excitement are always too much for me. I say this every race
            report, but it’s like being a kid on Christmas morning.
          </p>
          <p>
            It was a truly glorious experience to ride down the elevator, walk
            across the street, and be in transition. I got everything set up and
            returned to the hotel for some breakfast. No port-a-potty stop for
            this race!
          </p>
          <p>
            I must have been a little too relaxed about being so close to the
            race site. I made a careless error and forgot my water bottle for my
            bike in the hotel fridge! On top of that, I didn’t realize my
            mistake until just before we all had to board the school buses to be
            taken up the river to the swim start. I called Katy on my Apple
            Watch, which is as awkward as it sounds, and she ran down with the
            bottle for me.
          </p>
          <p>
            From there, it was onward to the stinky bus, and a number of lines.
            There’s a good bit of waiting around before bigger races, but I
            always have enjoyed the calm and sense of anticipation.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/hi-katy.jpg" alt="" />
            </figure>
            <figcaption className="figure-caption">"Hi, Katy"</figcaption>
          </div>
          <p>
            I heard the cannon go off for the pros, and then about 20 minutes
            later, I made my way down to the edge of the dock. Let’s go!
          </p>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Swim</h1>
          <p>
            <strong>Time:</strong> 39:49
            <br />
            <strong>Distance:</strong> 1.4 miles
            <br />
            <strong>Pace:</strong> 1:37/100 yd
            <br />
            <strong>AG:</strong> 198/267
            <br />
            <strong>Men:</strong> 1273/1790
            <br />
            <strong>Overall:</strong> 1787/2703
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Clear skies with temps in the 60s
            <br />
            <strong>Water Temperature:</strong> 74°F
            <br />
            <strong>Current:</strong> YEP!
            <br />
            <strong>Suit:</strong> Full-sleeved wetsuit
          </p>
          <p>
            This is such a fun swim. We get the whole river to ourselves, so
            crowding is rarely an issue. It’s also all downstream.
          </p>
          <p>
            The night before the race, we had some storms move through the area.
            As a result, the Tennessee River definitely had a nice current this
            morning. I swam a 1:37 pace compared to my average of about ~2:10 in
            a pool. Despite this course being an extra 0.2 miles compared to
            other 70.3 courses, I finished the swim about a minute faster than
            my previous 70.3 PR from last season at Blue Ridge!
          </p>
          <p>
            The swim portion is my weakest discipline of triathlon. But, at
            least on race day, it’s my favorite part of the event. It’s not
            often you get to jump off a dock into a vast river at sunrise. I
            always try to take a moment to look around and appreciate where I
            am. It was a truly stunning morning in Chatt.
          </p>
          <p>
            Especially in the COVID era, I really try to soak in moments like
            these. You don’t jump into a river to swim over a mile if you aren’t
            both physically and mentally healthy. And in 2022, that’s worth
            celebrating.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/dock.jpg" alt="" />
            </figure>
          </div>
          <p>&nbsp;</p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/flags.jpg" alt="" />
            </figure>
          </div>
          <p>
            Last fall doing the full IRONMAN here, I tripped and fell as I came
            out of the river, injuring my thumb. Nobody has ever taken a set of
            five stairs more carefully than I did at the end of this swim.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/swim-finish.jpg" alt="" />
            </figure>
          </div>
          <p>
            From there, it was a quick jog down a sidewalk to the WETSUIT
            STRIPPERS!!!
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/run-to-t1.jpg" alt="" />
            </figure>
          </div>
          <p>
            COVID social distancing protocols kept the wetsuit strippers away
            during the 2021 season. But for the first time since 2019, they were
            back! Maybe I’m weird, but I always love this part of IRONMAN races.
          </p>
          <p>
            Their job is to help you out of your wetsuit quickly. You unzip your
            wetsuit to your waist. Then you run up to one of them, lay on your
            back like a beached whale, put your legs in the air, and they rip
            your wetsuit off. It’s really satisfying to feel that thing go
            flying off in just a couple of seconds.
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 1: Swim to Bike</h1>
          <p>
            <strong>Time:</strong> 7:59
          </p>
          <p>
            It’s a pretty good jog up a hill to get to transition. Nobody’s
            setting transition personal records here compared to other courses.
          </p>
          <p>Wetsuit down, helmet on, and off I went.</p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Bike</h1>
          <p>
            <strong>Time:</strong> 3:05:12
            <br />
            <strong>Distance:</strong> 56 miles
            <br />
            <strong>Pace:</strong> 18.4 mph
            <br />
            <strong>AG:</strong> 171/267
            <br />
            <strong>Men:</strong> 1160/1790
            <br />
            <strong>Overall:</strong> 1518/2703
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Partly Cloudy with temps in the 70s
            <br />
            <strong>Road Conditions:</strong> Freshly paved roads in GA!
            <br />
            <strong>Terrain:</strong> Rolling, with a couple of steeper climbs
          </p>
          <p>
            I know this course quite well. Over the last three years, I’ve
            probably done this loop about 10 times between races and training
            rides. While my Wahoo will show me upcoming hills as I ride, I don’t
            really need that. I’m really familiar with this area and know how to
            pace myself. It’s a course of a bunch of rollers with a couple of
            punchy climbs. But overall, it’s a great ride.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/bike.jpg" alt="" />
            </figure>
          </div>

          <p>
            I’m disappointed that this ride was about five minutes slower than
            my 2021 race, where I finished in 2:59. With this being my second
            70.3 in two weeks, I expected some fatigue, but I’d hoped to at
            least match last year’s time.
          </p>

          <p>
            Rather than dwell on that, I want to spend a moment talking
            about...the Chickamauga Telephone Corporation. They have some sort of
            office around mile 45 on the course. About two months before my
            first 70.3 back in 2019, I came down to Chatt for the day and rode
            the course.
          </p>

          <p>
            It was a brutal ride and far harder than I expected. I was gassed as
            I came into Chickamauga.
          </p>
          <p>
            I pulled off in their parking lot to take a break. It was a lonely
            and helpless feeling standing there trying to catch my breath and
            find the strength to continue. It’s probably the closest point I
            ever came to giving up on triathlon and spending my time doing
            something else.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/ctc.jpg" alt="" />
            </figure>
            <figcaption className="figure-caption">
              Thanks to Google Street View, we can look at the site of my
              despair.
            </figcaption>
          </div>

          <p>
            It took me 4:10 to finally finish that training ride. A month later,
            when I completed my first 70.3, I managed to do the ride in 3:43.
            Today, I finished in 3:05. (On the heels of a 70.3 bike PR two weeks earlier at Gulf Coast 70.3!)
          </p>
          <p>
            I’m trying to do a better job of not constantly fixating on the next
            goal, the next time, the next whatever. I also want to appreciate
            the journey thus far. I’m still new to this sport and proud of the
            progress I’ve made!
          </p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 2: Bike to Run</h1>
          <p>
            <strong>Time:</strong> 9:34
          </p>
          <p>
            Two weeks ago at Gulf Coast 70.3, somebody crashed into me at the
            dismount line. Fortunately, nothing to write about here. I racked my
            bike, put my shoes on, and set off for the run.
          </p>
          <p>
            The only hiccup…a slight bathroom situation to tend to. There was
            somehow a LINE at the port-a-potties in transition! I’ve never seen
            this before, and it’s not like there was a lack of options. I guess
            we all had to go at the same time.
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Run</h1>
          <p>
            <strong>Time:</strong> 2:27:57
            <br />
            <strong>Distance:</strong> 13.1 miles
            <br />
            <strong>Pace:</strong> 11:30 / mile
            <br />
            <strong>AG:</strong> 180/267
            <br />
            <strong>Men:</strong> 1140/1790
            <br />
            <strong>Overall:</strong> 1550/2703
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Mostly Sunny with temps in the upper 80s
            <br />
            <strong>Road Conditions:</strong> Concrete except for parts of the
            river walk and bridges
            <br />
            <strong>Terrain:</strong> A bit hilly in sections
          </p>
          <p>
            While it does have some hills, this is a pretty fun half marathon.
            There are about five miles where everyone just bakes in the sun out
            on Riverside Drive/Amnicola Highway. That’s not so fun. But you also
            get a lot of miles on the Tennessee Riverwalk, and crossing over the
            river is always a treat, even if that dang Walnut Street Bridge is
            uphill.
          </p>

          <p>
            Good news: The bad weather stayed away until about an hour after I
            finished.
          </p>

          <p>Bad news: It was mostly sunny and a bit warmer than expected.</p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/run.jpg" alt="" />
            </figure>
            <figcaption className="figure-caption">
              Everyone looks miserable. But we’re all loving it.
            </figcaption>
          </div>

          <p>
            A year ago, I flamed out on this run in the heat around mile seven.
            I went quickly from feeling pretty good to needing to walk.
            Basically, I hit a wall and ran out of gas that day. I wanted to
            avoid that this year, and I did!
          </p>
          <p>
            I finished in 2:28, which is admittedly about half an hour slower
            than my best half marathon time. But it felt like a well-managed
            run. I pushed myself, but didn’t flame out. The sun was rough on the
            highway, but I handled it well, and held roughly a 10:30 pace in
            between walking through each aid station.
          </p>
          <p>
            There is always room for improvement, but I’m happy with how this
            went!
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/run-finish.jpg" alt="" />
            </figure>
          </div>
          <p>That finish line feeling never gets old.</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Postrace</h1>
          <p>
            <strong>Overall Time:</strong> 6:30:30
            <br />
            <strong>AG:</strong> 180/267
            <br />
            <strong>Men:</strong> 1140/1790
            <br />
            <strong>Overall:</strong> 1550/2703
            <br />
          </p>
          <p>
            Doing a 70.3 is always fun, but it’s better when you do it with a
            friend who qualified for worlds this fall! So excited for him!
          </p>

          <p>I always appreciate Katy coming to all IRONMAN events I do. This is admittedly a pretty boring spectator sport.</p>

          <div className="two-image">
            <img src="/images/chatt703-race-report/brian.jpg" alt="" />
            <img src="/images/chatt703-race-report/katy.jpg" alt="" />
          </div>

          <p>
            I’m already signed up for the 2023 race. I can’t wait to come back
            and do this all over again! I know I’m biased, but Chattanooga is
            the best venue.
          </p>

          <p>It's been an awesome month of May.</p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-race-report/medals.jpg" alt="" />
            </figure>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <h1>Next Steps</h1>
          <p>
            I’m taking a few days off around Memorial Day weekend to head to the
            Smoky Mountains with Katy.
          </p>

          <p>
            And after that, I have my third triathlon in six weeks. Fortunately,
            this one is not a 70.3. It’s just an Olympic distance, and it’s also
            in Nashville! The Music City Triathlon is always a good time. We
            start the swim by jumping off the side of the General Jackson
            Showboat! I can’t wait.
          </p>

          <div className="clearfix"></div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
